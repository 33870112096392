$(".navbar li.with-sub > a").each(function() {
	$(this).after("<span></span>");
});

function mobileCheck() {
	if ($(".seat-select").length) {
		var wrp = $('.seat-select');
		var inc = $('.seat-select > ul');
		wrp.scrollLeft((inc.width() - wrp.width()) / 2);
	}
}

function countSum(){
	var count = $(".order-summary article").size();
	var total = 0;
	$('.order-summary article:not(.dublicate)').each(function(){
		console.log((+total+parseFloat($(this).find("select option:selected").attr("data-price"))));
		total = (+total+parseFloat($(this).find("select option:selected").attr("data-price")))
	});
	$(".order-summary .amount strong").text(count);
	$(".order-summary .amount input").val(count);
	$(".order-summary .total strong").text(total.toFixed(2)+"€");
	$(".order-summary .total input").val(total.toFixed(2));
}

$(document).ready(function() {
	mobileCheck();
	$(window).resize(function() {
		mobileCheck();
	});

	if (!$(".coupes").length) {
		countSum();
	};

	$(".added select").crfs();

	$(".movie-list h3").each(function() {
		var sbs= $(this);
		var sb = $(this).find("span");
		$(window).scroll(function() {
			var offset = sbs.offset();
			if ($(window).scrollTop() > offset.top) {
				if ($(window).scrollTop() < offset.top +sb.parent().parent().outerHeight()) {
					sb.parent().addClass("moved");
				} else {
					sb.parent().removeClass("moved");
				};
			} else {
				sb.parent().removeClass("moved");
			};
		});
	});

	$(".navbar li span").click(function() {
		$(this).parent().toggleClass("active");
		return false;
	});

	$(".coupes .qnt input").on("change keypress", function() {
		var totalC = 0;
		var totalP = 0;
		$(".qnt input").each(function() {
			var qnt = parseInt($(this).val());
			var price = parseFloat($(this).attr("data-price"));
			totalC = totalC + qnt;
			totalP = totalP + price *qnt;
			console.log(totalP);
		});
		$(".sum.amount strong").text(totalC);
		$(".sum.amount input").val(totalC);
		$(".sum.total strong span").text(totalP.toFixed(2));
		$(".sum.total input").val(totalP.toFixed(2));
	});

	$(".qnt .plus").click(function() {
		$(this).parent().find("input").val(parseInt($(this).parent().find("input").val())+1).change();
		return false;
	});

	$(".qnt .minus").click(function() {
		if ($(this).parent().find("input").val() > 0) {
			$(this).parent().find("input").val(parseInt($(this).parent().find("input").val())-1).change();
		}
		return false;
	});

	$(".tabs-nav a").click(function() {
		$(".tabs-nav .active").removeClass("active")
		$(this).addClass("active");
		$(".tab-content").removeClass("active");
		$($(this).attr("href")).addClass("active");
		return false;
	});

	$(".accordion h3").click(function() {
		if ($(this).hasClass("active")) {
			$(".accordion h3").removeClass("active");
			$(".accordion .content").slideUp(300);
		} else {
			$(".accordion h3").removeClass("active");
			$(".accordion .content").slideUp(300);
			$(this).addClass("active").next().slideDown(300);
		}
		return false;
	});

	$('.slider .slides').slick({
		dots: true,
		infinite: true,
		speed: 600,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [{
			breakpoint: 991,
			settings: {
				variableWidth: false,
			}
		}]
	});

	$(document).on('change', 'article.added select', function() {
		countSum();
	});

	$(document).on('change', '.seat-select input', function() {
		$('.seat-select input').each(function(){
			if ($(this).is(":checked")) {
				if (!$(".order-summary article[data-id="+$(this).attr("id")+"]").length) {
					var clone = $(".dublicate").clone().removeClass("hide dublicate").addClass("added").attr("data-id", $(this).attr("id"));
					clone.find(".line").text($(this).next().find(".line").text());
					clone.find(".seat").text($(this).next().find(".number").text());
					clone.find("select").attr("name", "places["+$(this).next().find(".line").text()+"]["+$(this).next().find(".number").text()+"]");
					clone.appendTo(".order-summary section");
					$(".added select").crfs();
				}
			} else {
				$(".order-summary article[data-id="+$(this).attr("id")+"]").remove();
			};
		});
		countSum();
	});

	$('[placeholder]').each(function() {
		var input = $(this);

		$(input).focus(function(){
			if (input.val() == input.attr('placeholder')) {
				input.val('').removeClass("placeholder");
			}
		});

		$(input).blur(function(){
			if (input.val() == '' || input.val() == input.attr('placeholder')) {
				input.val(input.attr('placeholder')).addClass("placeholder");
			}
		});
	}).blur();

	$(".banks-c input").change(function() {
		var checked = $(this).is(':checked');
		$(".banks-c input").prop('checked',false);
		$(".banks-c").removeClass("selected");
		if(checked) {
			$(this).prop('checked',true);
			$(".banks-c").addClass("selected");
		}
	});

	$('#check-code').on('click', function(e){
		e.preventDefault();
		var code = $('[name="code"]').val();
		var selected_places = $('.seat-select input:checked').length;

		$.ajax({
			url: "/kvietimai/patikrinti",
			method: 'GET',
			data: {
				code: code,
				selected_places: selected_places,
				_token: $('meta[name="csrf-token"]').attr('content')
			},
			success: function(result){
				if(result.result == 'ok'){
					setCodeSuccess(result.message);
					$('.sum.total strong').text('0.00€');
				} else {
					setCodeError(result.message);
				}
			},
			error: function(){
				setCodeError('Įvyko klaida, prašome bandyti vėliau arba susisiekti su administracija.');
			}
		});

	});

	function setCodeError(error){
		resetCodeForm();
		$('.code-form p').text(error);
		$('.code-form p').addClass('error');
		$('.code-form .form-control').addClass('error');
	}

	function setCodeSuccess(message){
		resetCodeForm();
		$('.code-form p').text(message);
		$('.code-form p').addClass('success');
		$('.code-form .form-control').addClass('success');
	}

	function resetCodeForm(){
		$('.code-form p').text('');
		$('.code-form p').removeClass();
		$('.code-form .form-control').attr('class', 'form-control');
	}

	$('.play').fancybox({
		helpers : {
			media : {}
		}
	});
});